<template>
  <Header title="Pemetaan Posting Jurnal" link1="Akuntansi" link2="Pemetaan Posting Jurnal"></Header>

  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="map2-tab" data-bs-toggle="tab" data-bs-target="#map2" type="button" role="tab" aria-controls="map2" aria-selected="true">Pemetaan Umum</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="map-tab" data-bs-toggle="tab" data-bs-target="#map" type="button" role="tab" aria-controls="map2" aria-selected="false">Pemetaan SPP</button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="map2" role="tabpanel" aria-labelledby="map2-tab">
      <div class="row pt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="text-secondary d-inline">Pemetaan Posting Jurnal</h5>
                </div>
                <div class="col-md-8">
                  <div class="float-end">
                    <button class="btn btn-circle btn-gradient" @click="add2()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Pemetaan Jurnal</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="float-end">
                <div class="input-group mb-3">
                  <input type="text" v-model="search2" class="form-control" placeholder="Pencarian" aria-label="Pencarian">
                  <span class="input-group-text" id="basic-addon2"> <ion-icon name="search"></ion-icon> </span>
                </div>
              </div>
              <table class="table table-borderless table-data fw-bold">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Pemetaan</th>
                    <th>Deskripsi</th>
                    <th>Status</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(map, index) in filteredList2" :key="map.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ map.name }}</td>
                    <td>{{ map.description }}</td>
                    <td><span v-if="map.status == 1" class="label label-success fw-bold">Aktif</span></td>
                    <td>
                      <a class="btn btn-sm btn-link-gray" href="#" @click="edit2(map.id)">Edit <ion-icon name="create-outline"></ion-icon></a>
                      <a class="btn btn-sm btn-link-gray" href="#" @click="delete2(index)">Delete <ion-icon name="trash-outline"></ion-icon></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="map" role="tabpanel" aria-labelledby="map-tab">
      <div class="row pt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="text-secondary d-inline">Pemetaan Posting Jurnal</h5>
                </div>
                <div class="col-md-8">
                  <div class="float-end">
                    <button class="btn btn-circle btn-gradient" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Pemetaan Jurnal</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="float-end">
                <div class="input-group mb-3">
                  <input type="text" v-model="search" class="form-control" placeholder="Pencarian" aria-label="Pencarian">
                  <span class="input-group-text" id="basic-addon2"> <ion-icon name="search"></ion-icon> </span>
                </div>
              </div>
              <table class="table table-borderless table-data fw-bold">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Pemetaan</th>
                    <th>ID Mapping</th>
                    <th>Deskripsi</th>
                    <th>Status</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(map, index) in filteredList" :key="map.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ map.name }}</td>
                    <td>{{ map.string_id }}</td>
                    <td>{{ map.description }}</td>
                    <td><span v-if="map.status == 1" class="label label-success fw-bold">Aktif</span></td>
                    <td>
                      <a class="btn btn-sm btn-link-gray" href="#" @click="edit(index)">Edit <ion-icon name="create-outline"></ion-icon></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="journalMapModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="state == 'add'">Tambah Pemetaan Jurnal</h5>
          <h5 class="modal-title" v-if="state == 'edit'">Ubah Pemetaan Jurnal</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3" @submit="">
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Nama Pemetaan</label>
              <input type="text" class="form-control" v-model="data.name" @change="generateStringID" required>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">ID Mapping
                <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="String ID yang akan diinput ke Tipe Rincian Tagihan">
                  <ion-icon name="information-circle-outline"></ion-icon>
                </a>
              </label>
              <input type="text" class="form-control" v-model="data.string_id" :readonly="state == 'edit'" required>
            </div>
            <div class="col-12">
              <table class="table">
                <tbody>
                  <tr><th></th><th>Debit</th><th>Kredit</th></tr>
                  <tr>
                    <th>Penagihan
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merupakan nomor akun yang otomatis terinput ke jurnal saat terjadi publish tagihan">
                        <ion-icon name="information-circle-outline"></ion-icon>
                      </a></th>
                    <th><input type="text" class="form-control" v-model="data.details[0].bill_debit_account_id"></th>
                    <th><input type="text" class="form-control" v-model="data.details[0].bill_credit_account_id"></th>
                  </tr>
                  <tr>
                    <th>Pelunasan
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merupakan nomor akun yang otomatis terinput ke jurnal saat terjadi pelunasan tagihan">
                        <ion-icon name="information-circle-outline"></ion-icon>
                      </a>
                    </th>
                    <th><input type="text" class="form-control" v-model="data.details[0].payment_debit_account_id" required></th>
                    <th><input type="text" class="form-control" v-model="data.details[0].payment_credit_account_id" required></th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Keterangan</label>
              <textarea class="form-control text-secondary" rows="2" v-model="data.description"></textarea>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Status</label>
              <div class="form-checkbox">
                <input class="form-check-input" type="checkbox" v-model="data.status">
                <span> Aktif</span>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save()"> <ion-icon name="save-outline"></ion-icon> Simpan</button>
        </div>
      </div>
    </div>
  </div>

  <div id="journalMapModal2" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="state == 'add'">Tambah Pemetaan Jurnal</h5>
          <h5 class="modal-title" v-if="state == 'edit'">Ubah Pemetaan Jurnal</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3" @submit="">
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Nama Pemetaan</label>
              <input type="text" class="form-control" v-model="data2.name" required>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Tipe Transaksi</label>
              <select v-model="data2.acc_journal_map2_type_id" class="text-primary form-select">
                <option value="">Pilih Tipe Transaksi</option>
                <option
                  v-for="mt in journal_map2_types"
                  :key="mt.id"
                  :label="mt.name"
                  :value="mt.id">
                </option>
              </select>
            </div>
            <div class="col-12">
              <table class="table">
                <thead>
                  <tr><th>Account Code</th><th>Debit</th><th>Credit</th><th></th></tr>
                </thead>
                <tbody>
                  <tr v-for="(detail, i) in data2.details">
                    <th> {{ detail.acc_account_id }} - {{ detail.acc_account_name }} </th>
                    <th> <ion-icon v-if="detail.debit" name="checkmark"></ion-icon> </th>
                    <th> <ion-icon v-if="detail.credit" name="checkmark"></ion-icon> </th>
                    <th> <button type="button" class="btn btn-outline-secondary"><ion-icon name="trash" @click="removeDetail(i)"></ion-icon></button> </th>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td><input placeholder="No Akun" v-model="keyword" class="text-primary form-select" type="text" autocomplete="off" @input="getSuggestions(keyword, 'modal')" @focus="modal = true">
                      <div v-if="accounts.length > 0 && autocomplete == 'modal' && keyword.length > 3">
                        <ul class="container-autocomplete">
                            <li class="item-autocomplete" v-for="account in accounts" :key="account.id" @click="setSearch(account)">{{account.id}} - {{ account.name }}</li>
                        </ul>
                      </div>
                    </td>
                    <td><div class="form-check">
                      <input class="form-check-input" type="checkbox" id="detail_debit" v-model="detailData.debit">
                      <label class="form-check-label" for="detail_debit">
                        Debit
                      </label>
                    </div></td>
                    <td><div class="form-check">
                      <input class="form-check-input" type="checkbox" id="detail_credit" v-model="detailData.credit">
                      <label class="form-check-label" for="detail_credit">
                        Credit
                      </label>
                    </div></td>
                    <td>
                      <button type="button" class="btn btn-primary" @click="addDetail()"><ion-icon name="add-circle"></ion-icon></button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Keterangan</label>
              <textarea class="form-control text-secondary" rows="2" v-model="data2.description"></textarea>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Status</label>
              <div class="form-checkbox">
                <input class="form-check-input" type="checkbox" v-model="data.status">
                <span> Aktif</span>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save2()"> <ion-icon name="save-outline"></ion-icon> Simpan</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script language="ts" src="./scripts/setting.ts"></script>
