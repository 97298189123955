// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import * as bootstrap from 'bootstrap'
import swal from 'sweetalert'
import Swal from 'sweetalert'
import { journalFactory } from '@/http/journal'
import { accountFactory } from '@/http/account'
import utils from '@/utils/utils'

@Options({
  components: {
    Header
  },
  data() {
    return {
      // Speciasl Journal Map
      accounts: [],
      journal_maps: [],
      search: '',
      tooltipList: null,
      data: {
        id: null,
        string_id: '',
        name: '',
        description: '',
        status: true,
        details: [
          {
            bill_debit_account_id: null,
            bill_credit_account_id: null,
            payment_debit_account_id: null,
            payment_credit_account_id: null
          }
        ]
      },
      showModal: false,
      state: '',

      // Common Journal Map
      journal_maps2: [],
      journal_map2_types: [],
      search2: '',
      tooltipList2: null,
      data2: {
        id: null,
        acc_journal_map2_type_id: '',
        name: '',
        description: '',
        status: true,
        details: [],
        deleted_details: []
      },
      detailData: {
        acc_account_id: 0,
        acc_account_name: '',
        debit: false,
        credit: false
      },
      showModal2: false,
      state2: '',
      keyword: ''
    }
  },
  methods: {
    async getSuggestions(keyword: string, type: string) {
      if (keyword.length >= 3) {
        Promise.resolve(accountFactory.search(keyword))
        .then(result => {
          this.accounts = result.data.accounts
          this.autocomplete = type
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.accounts = []
        })
      } else {
        this.accounts = []
      }
    },
    setSearch(search:any){
      this.keyword = search.id
      this.detailData.acc_account_id = search.id
      this.detailData.acc_account_name = search.name
      this.accounts = [];
    },
    addDetail: function() {
      if (!this.detailData.acc_account_id) {
        swal("No akun tidak boleh kosong", "", "error")
        return
      }

      if (!this.detailData.debit && !this.detailData.credit) {
        swal("Debit atau kredit harus aktif salah satunya", "", "error")
        return
      }

      this.data2.details.push(this.detailData)
      this.detailData = {
        acc_account_id: null,
        acc_account_name: '',
        debit: false,
        credit: false
      }
      this.keyword = ''
    },
    removeDetail: function(i: number) {
      this.data2.deleted_details.push(this.data2.details[i].id)
      this.data2.details.splice(i, 1)
    },
    generateStringID: function(name: string) {
      // var name = this.data.name
      var string_id = name.replace(/ /g,"_")
      this.data.string_id = string_id.toLowerCase()
    },

    /* Special Journal Mapping */
    loadData: function(type:any) {
      Promise.all([
        journalFactory.getMaps()
      ]).then(results => {
        this.journal_maps = results[0].data.journal_maps
      }).catch((e) => {
        swal("Gagal load data", "", "error")
        console.log(e)
      })
    },
    save: function() {
      if (this.data.name == '') {
        swal("Nama tidak boleh kosong", "", "warning")
        return
      }
      if (this.state == 'add') {
        // this.data.status = (this.data.status) ? 1 : 0,
        Promise.all([
          journalFactory.createMap(this.data),
        ]).then(results => {
          if (results[0].error == false) {
            swal("Sukses menyimpan pemetaan", "", "success")
            this.journalMapModal.hide()

            var journal_map = results[0].data.journal_map
            this.journal_maps.push(journal_map)
          } else {
            swal("Gagal menyimpan pemetaan", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan pemetaan", "", "error")
          console.log(e)
        })
      } else {
        Promise.all([
          journalFactory.updateMap(this.data.id, this.data),
        ]).then(results => {
          if (results[0].error == false) {
            swal("Sukses menyimpan pemetaan", "", "success")
            this.journalMapModal.hide()

            var journal_map = results[0].data.journal_map
            var index = utils.indexSearch(this.journal_maps, 'id', journal_map.id)
            this.journal_maps[index] = journal_map
          } else {
            swal("Gagal menyimpan pemetaan", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan pemetaan", "", "error")
          console.log(e)
        })
      }
    },
    edit: function(index: any) {
      // open modal with selected data
      this.state = 'edit'
      this.journalMapModal.show()
      this.data = this.journal_maps[index]
      this.data.status = (this.data.status == 1) ? true : false
    },
    add: function() {
      this.state = 'add'
      this.journalMapModal.show()
      this.data = {
        id: null,
        string_id: '',
        name: '',
        description: '',
        status: true,
        details: [
          {
            bill_debit_account_id: null,
            bill_credit_account_id: null,
            payment_debit_account_id: null,
            payment_credit_account_id: null
          }
        ]
      }
      this.state = 'add'
    },

    /* Common Journal Map Section */
    loadData2: function(type:any) {
      Promise.all([
        journalFactory.getMaps2()
      ]).then(results => {
        this.journal_maps2 = results[0].data.journal_maps2
      }).catch((e) => {
        swal("Gagal load data", "", "error")
        console.log(e)
      })
    },
    save2: function() {
      if (this.data2.name == '') {
        swal("Nama tidak boleh kosong", "", "warning")
        return
      }
      if (this.state2 == 'add') {
        // this.data.status = (this.data.status) ? 1 : 0,
        Promise.all([
          journalFactory.createMap2(this.data2),
        ]).then(results => {
          if (results[0].error == false) {
            swal("Sukses menyimpan pemetaan", "", "success")
            this.journalMapModal2.hide()

            var journal_map2 = results[0].data.journal_map2
            this.journal_maps2.push(journal_map2)
          } else {
            swal("Gagal menyimpan pemetaan", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan pemetaan", "", "error")
          console.log(e)
        })
      } else {
        Promise.all([
          journalFactory.updateMap2(this.data2.id, this.data2),
        ]).then(results => {
          if (results[0].error == false) {
            swal("Sukses menyimpan pemetaan", "", "success")
            this.journalMapModal2.hide()

            var journal_map2 = results[0].data.journal_map2
            var index = utils.indexSearch(this.journal_maps2, 'id', journal_map2.id)
            this.journal_maps2[index] = journal_map2
          } else {
            swal("Gagal menyimpan pemetaan", "", "error")
          }
        }).catch((e) => {
          swal("Gagal menyimpan pemetaan", "", "error")
          console.log(e)
        })
      }
    },
    edit2: function(id: number) {
      Promise.all([
        journalFactory.getMap2Detail(id),
      ]).then(results => {
        var journal_map2 = results[0].data.journal_map2;
        if (journal_map2) {
          // open modal with selected data
          this.state2 = 'edit'
          this.journalMapModal2.show()
          this.data2 = journal_map2
          this.data2.status = (this.data2.status == 1) ? true : false
          this.data2.deleted_details = []
        } else {
          swal("Data gagal didapatkan", "", "error")
        }
      }).catch((e) => {
        swal("Data gagal didapatkan", "", "error")
        console.log(e)
      })
    },
    add2: function() {
      this.state2 = 'add'
      this.journalMapModal2.show()
      this.data2 = {
        id: null,
        acc_journal_map2_type_id: '',
        name: '',
        description: '',
        status: true,
        details: [],
        deleted_details: []
      }
      this.state2 = 'add'
    },
    delete2: function(index: number) {
      swal({
        title: "Apakah anda yakin?",
        text: "Pengaturan pemetaan jurnal akan dihapus, anda yakin?",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          var journal_map2 = this.journal_maps2[index]
          if (!journal_map2) {
            swal("Gagal menghapus pengaturan", "", "error")
            console.log('invalid journal_map2')
            return false
          }

          Promise.all([
            journalFactory.deleteMap2(journal_map2.id),
          ]).then(results => {
            if (results[0].error == false) {
              swal("Sukses menghapus pengaturan", "", "success")

              this.journal_maps2.splice(index, 1)
            } else {
              swal("Gagal menghapus pengaturan", "", "error")
            }
          }).catch((e) => {
            swal("Gagal menghapus pengaturan", "", "error")
            console.log(e)
          })
        }
      })
    }
  },
  async mounted () {

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        animation: false
      })
    })

    var journalMapModal = document.getElementById('journalMapModal')
    if (journalMapModal != null) {
      this.journalMapModal = new bootstrap.Modal(journalMapModal)
    }
    this.journalMapModal.hide()

    var journalMapModal2 = document.getElementById('journalMapModal2')
    if (journalMapModal2 != null) {
      this.journalMapModal2 = new bootstrap.Modal(journalMapModal2)
    }
    this.journalMapModal2.hide()

    await Promise.all([
      journalFactory.getMaps2type(),
    ]).then(results => {
      this.journal_map2_types = results[0].data.journal_map2_types
    })

    this.loadData()
    this.loadData2()
  },
  computed: {
    filteredList() {
      if (this.journal_maps.length > 0) {
        return this.journal_maps.filter((map:any) => {
          return map.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return []
    },
    filteredList2() {
      if (this.journal_maps2.length > 0) {
        return this.journal_maps2.filter((map:any) => {
          return map.name.toLowerCase().includes(this.search2.toLowerCase())
        })
      }

      return []
    }
  }
})

export default class Setting extends Vue {}
